'use client'

import { useRouter } from 'next/router'
import { useAuth } from '../providers'
import LoginPage from '@/ui/pages/loginPage'

export default function Login() {
  const router = useRouter()
  const { isLogged } = useAuth()

  if (isLogged) {
    router.push('/')
    return null
  }

  return <LoginPage />
}
