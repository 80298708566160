'use client'

import React, { ReactNode } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import usePersistedTheme from '@/hooks/usePersistedTheme'
import useTranslation from '@/hooks/useTranslation/useTranslation'
import SelectLang from '@/ui/atoms/SelectLang'
import SelectTheme from '@/ui/atoms/SelectTheme'
import Typography from '@/ui/atoms/Typography'

type Props = {
  children: ReactNode
}

const AuthTemplate: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation('authTemplate')
  const { theme } = usePersistedTheme()

  const logoSrc =
    theme === 'dark'
      ? '/assets/logos/signater/yellow-black-square-with-white-text.png'
      : '/assets/logos/signater/yellow-black-square-with-black-text.png'

  return (
    <div className="relative h-full min-h-screen bg-gray-50 md:flex">
      <div className="flex flex-col items-center justify-center w-full p-6">
        <div className="rounded-3xl justify-start items-start gap-2.5 inline-flex p-8 py-12 bg-accent-100 shadow w-full sm:w-[450px] sm:px-14 sm:py-10">
          <div className="inline-flex flex-col w-full gap-6">
            <div className="flex items-center justify-between">
              <div className="relative">
                <Image width={176} height={36} src={logoSrc} alt="Signater" />
              </div>
              <div className="flex items-center justify-between gap-x-2">
                <SelectTheme />
                <SelectLang />
              </div>
            </div>
            {children}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-2 mt-6 sm:flex-row sm:justify-start sm:items-start sm:gap-6 sm:mt-14">
          <Typography
            variant="text-xs-medium"
            className="text-gray-700 dark:text-gray-700"
          >
            © Signater {new Date().getFullYear()}. {t?.allRightsReserved}
          </Typography>
        </div>
        <div className="flex flex-col items-center justify-center gap-2 mt-2 sm:flex-row sm:justify-start sm:items-start sm:gap-6">
          <div className="flex items-center justify-center gap-2">
            <Link
              href="https://signater.com.br/juridico/termos-de-uso"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography
                variant="text-xs-medium"
                className="text-secondary-700"
              >
                {t?.terms}
              </Typography>
            </Link>
            <Link
              href="https://signater.com.br/juridico/uso-de-cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography
                variant="text-xs-medium"
                className="text-secondary-700"
              >
                {t?.cookies}
              </Typography>
            </Link>
            <Link
              href="https://signater.com.br/juridico/politicas-de-privacidade"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography
                variant="text-xs-medium"
                className="text-secondary-700"
              >
                {t?.privacy}
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthTemplate
