import React, { useCallback, useEffect, useRef, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import useLocalStorage from '@/hooks/useLocalStorage'
import { useAuth } from '@/providers/Auth'
import { useQueryParams } from '@/providers/QueryParams'
import useTranslation from '@/hooks/useTranslation/useTranslation'
import Button from '@/ui/atoms/Button'
import Checkbox from '@/ui/atoms/Checkbox'
import Typography from '@/ui/atoms/Typography'
import { Form, RenderFormField } from '@/ui/molecules/Form'
import AuthTemplate from '@/ui/templates/AuthTemplate'

type FormValues = {
  email: string
  password: string
  rememberMe: boolean
}

const LoginPage = () => {
  const { t, isReady } = useTranslation(['login', 'seo', 'validations'], true)
  const [emailLocal, setEmailLocal, isLoadingGetEmailLocal] = useLocalStorage(
    'rememberLoginEmail',
    ''
  )
  const [insertedInitialValues, setInsertedInitialValues] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    getQueryParam,
    setQueryParam,
    isReady: isReadyQueryParams
  } = useQueryParams()
  const { push } = useRouter()
  const { loading, loginAction, urlFederatedLogin, setLoading } = useAuth()

  const passwordInputRef = useRef<HTMLInputElement>(null)

  const formSchema = z.object({
    email: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(2, { message: t?.errorMinLength?.(2) })
      .max(100, { message: t?.errorMaxLength?.(100) })
      .email({ message: t?.errorInvalidEmail }),
    password: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(8, { message: t?.errorMinLength?.(2) })
      .max(100, { message: t?.errorMaxLength?.(100) }),
    rememberMe: z.boolean()
  })

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false
    }
  })

  const valueRememberMe = useWatch({
    control: form.control,
    name: 'rememberMe',
    defaultValue: false
  })

  useEffect(() => {
    if (
      isReadyQueryParams &&
      !insertedInitialValues &&
      !isLoadingGetEmailLocal
    ) {
      const emailQuery = getQueryParam('email', null)
      const email = emailQuery || emailLocal
      const clickGoogleLogin = getQueryParam('clickGoogleLogin', null)

      if (emailLocal) {
        form.setValue('rememberMe', true)
      }
      if (email) {
        form.setValue('email', email)
        if (emailQuery) {
          setQueryParam('email', null)
        }
      }
      if (clickGoogleLogin) {
        push(urlFederatedLogin)
      }
      setInsertedInitialValues(true)
    }
  }, [
    emailLocal,
    form,
    getQueryParam,
    insertedInitialValues,
    isLoadingGetEmailLocal,
    isReadyQueryParams,
    push,
    setQueryParam,
    urlFederatedLogin
  ])

  const onSubmit = useCallback(
    async (values: FormValues) => {
      setIsLoadingSubmit(true)
      setLoading(true)

      if (!values.rememberMe) {
        setEmailLocal('')
      } else {
        setEmailLocal(values.email)
      }
      const token = executeRecaptcha
        ? await executeRecaptcha('login_with_credentials')
        : ''
      try {
        await loginAction({
          ...values,
          recaptchaToken: token
        })
        passwordInputRef?.current?.blur()
      } catch (error: any) {
        console.log(error)
      } finally {
        setIsLoadingSubmit(false)
      }
    },
    [executeRecaptcha, loginAction, setEmailLocal, setLoading]
  )

  return (
    <AuthTemplate>
      <div className="flex flex-col items-start justify-start gap-6">
        <div className="flex flex-col items-start justify-start gap-1">
          <Typography
            type="h1"
            variant="title-2xl-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={!isReady}
          >
            {t?.title}
          </Typography>
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={!isReady}
          >
            {t?.subTitle}
          </Typography>
        </div>
        <Link href={urlFederatedLogin} passHref className="w-full">
          <Button
            variant="neutral"
            className="flex items-center justify-center w-full gap-2 py-2 pl-3 pr-3 border border-gray-300 rounded-md shadow bg-accent-100 hover:text-gray-100"
            fullWidth
            showSkeleton={!isReady}
          >
            <Image
              src="/assets/icons/google.svg"
              alt="google"
              width={16}
              height={16}
            />
            <Typography
              type="span"
              variant="text-sm-medium"
              className="text-inherit"
            >
              {t?.buttonGoogle}
            </Typography>
          </Button>
        </Link>
        <div className="inline-flex items-center self-stretch justify-center gap-2">
          <div className="grow shrink basis-0 h-[1px] bg-gray-300" />
          <Typography
            type="p"
            variant="text-sm-regular"
            className="text-gray-500"
            showSkeleton={!isReady}
          >
            {t?.orContinue}
          </Typography>
          <div className="grow shrink basis-0 h-[1px] bg-gray-300" />
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <Form {...form} onSubmit={onSubmit}>
          <>
            <RenderFormField
              control={form.control}
              name="email"
              type="input"
              showSkeleton={!isReady}
              {...form.formState.errors.email}
              {...t?.form?.email}
            />
            <RenderFormField
              control={form.control}
              name="password"
              type="password"
              showSkeleton={!isReady}
              ref={passwordInputRef}
              {...form.formState.errors.password}
              {...t?.form?.password}
            />
            <div className="flex items-center justify-between">
              <Checkbox
                id="rememberMe"
                value={valueRememberMe as unknown as string}
                checked={valueRememberMe}
                onChange={(e) => {
                  form.setValue('rememberMe', e.target.checked)
                }}
                label={t?.remember}
                name="rememberMe"
                showSkeleton={!isReady}
              />
              <div className="text-sm leading-6">
                <Link href="/forgot-password" passHref>
                  <Typography
                    variant="text-sm-semibold"
                    className="cursor-pointer text-secondary-700"
                    showSkeleton={!isReady}
                  >
                    {t?.recovery}
                  </Typography>
                </Link>
              </div>
            </div>
            <Button
              type="submit"
              fullWidth
              loading={loading || isLoadingSubmit}
              showSkeleton={!isReady}
            >
              {t?.login}
            </Button>
          </>
        </Form>
        <div className="inline-flex items-center justify-center gap-1 sm:gap-2">
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={!isReady}
          >
            {t?.registerPrefix}
          </Typography>
          <Link href="/register" passHref>
            <Typography
              variant="text-sm-semibold"
              className="cursor-pointer text-secondary-700"
              showSkeleton={!isReady}
            >
              {t?.register}
            </Typography>
          </Link>
        </div>
      </div>
      <NextSeo
        title="Signater - Login"
        description="Faça login na sua conta Signater"
        canonical="https://app.signater.com.br/login"
        openGraph={{
          url: 'https://app.signater.com.br/login',
          title: 'Signater - Login',
          description: 'Faça login na sua conta Signater',
          type: 'website',
          images: [{ url: '/public/meta/signater.webp' }],
          siteName: 'Signater',
          locale: 'pt_BR'
        }}
        twitter={{
          site: 'https://signater.com.br',
          cardType: 'summary_large_image'
        }}
      />
    </AuthTemplate>
  )
}

export default LoginPage
